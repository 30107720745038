<template>
  <div class="department-detail">
    <department-base-info
      :department="departmentInfo"
      v-loading="baseInfoLoading"
      @refresh-base-info="getDepartmentDetail"
    ></department-base-info>
    <department-comment-list
      ref="commentList"
      :departmentId="departmentId"
    ></department-comment-list>

    <div class="lbd-sign"></div>
  </div>
</template>

<script>
import DepartmentBaseInfo from './layout/department-base-info.vue';
import DepartmentCommentList from './layout/department-comment-list.vue';
import DepartmentService from '#/js/service/departmentService.js';
export default {
  components: {
    DepartmentBaseInfo,
    DepartmentCommentList
  },
  data() {
    return {
      departmentInfo: {
        id: null,
        customerId: "",
        name: "",
        parentName: "",
        memberCount: 0,
        description: "",
        business: "",
        requirement: "",
        interviewprocess: "",
        salarystructure: "",
        groupVersion: ''
      },
      customerId: "",
      departmentId: "",
      baseInfoLoading: false,
    }
  },
  mounted() {
    this.customerId = this.$route.params.customerId;
    this.departmentId = this.$route.params.id;
    this.getDepartmentDetail();
  },
  methods: {
    getDepartmentDetail() {
      this.baseInfoLoading = true;
      DepartmentService.getDepartmentDetail({
        customerId: this.customerId,
        id: this.departmentId
      }).then(res => {
        this.baseInfoLoading = false;
        let obj = Object.assign({}, this.departmentInfo, res);
        this.departmentInfo = obj;
        this.$refs.commentList.getDepartmentComments();
        let firmName = this.$store.state.user.userInfo.firmShortName;
        let title = '组织架构_' + res.name + '_' + firmName + '_' + '猎必得_最有效的O2O猎头招聘平台';
        document.title = title;
      }).catch(err => {
        this.baseInfoLoading = false;
        console.log(err);
      });
    }
  }
}
</script>

<style lang="scss" scope>
.department-detail {
  height: 100%;
  overflow-y: auto;
  padding: 20px 20px 30px;

  .lbd-sign {
      padding-top: 20px;
    &:after {
      content: '© 2024 liebide.com';
      margin-left: 50%;
      text-align: center;
      font-size: 14px;
      color: #999;
    }
  }
}
</style>