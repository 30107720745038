var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "department-detail" },
    [
      _c("department-base-info", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.baseInfoLoading,
            expression: "baseInfoLoading",
          },
        ],
        attrs: { department: _vm.departmentInfo },
        on: { "refresh-base-info": _vm.getDepartmentDetail },
      }),
      _c("department-comment-list", {
        ref: "commentList",
        attrs: { departmentId: _vm.departmentId },
      }),
      _c("div", { staticClass: "lbd-sign" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }