<template>
    <div class="department-comment-item">
        <div class="comment-item-left">
            <p class="comment-text">{{comment.content}}</p>
            <div class="comment-appraise">
                <span
                    class="appraise-btn text-operate-btn grey"
                    :class="{'is-like': comment.isLike}"
                    @click="$emit('appraise-comment', comment)">
                    <i class="el-icon-star-off appraise-icon" v-if="!comment.isLike"></i><i class="el-icon-star-on appraise-icon" v-if="comment.isLike"></i>有用
                </span>
                <span class="appraise-tip">
                    {{comment.likeCount}} 位用户觉得该评论有用
                </span>
            </div>
        </div>
        <div class="comment-create">
            <avatar
                size="md"
                placement="top"
                class="comment-create-creator"
                :src="comment.avatar"
                :alt="comment.creatorName"
                :userId="comment.creatorId"
            ></avatar>
            <span class="comment-create-time">
                {{comment.created | timeFilter}} 发布
            </span>
        </div>
    </div>
</template>

<script>
import Avatar from '#/component/common/avatar.vue';
import moment from 'moment';
export default {
    props: {
        comment: {
            type: Object
        }
    },
    components: {
        Avatar
    },
    filters: {
        timeFilter(val) {
            return moment(val).format('YYYY-MM-DD');
        }
    }
}
</script>

<style lang="scss" scope>
.department-comment-item {
    border: 1px solid #ddd;
    padding: 20px 24px 20px;
    display: flex;
    .comment-item-left {
        flex-grow: 1;
        max-width: 1000px;
    }
    .comment-text {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 20px;
        color: #666;
        white-space: pre-wrap;
        word-break: break-all;
    }
    .comment-appraise {
        line-height: 20px;
        .appraise-btn.is-like {
            color: $primary;
            cursor: default;
            text-decoration: none;
        }
        .appraise-icon {
            font-size: 18px;
            position: relative;
            top: 1px;
        }
        .appraise-tip {
            margin-left: 40px;
            color: #999;
        }
    }
    .comment-create {
        height: 100%;
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-time {
            margin-top: 10px;
            font-size: 12px;
            color: #999;
        }
    }
}
</style>