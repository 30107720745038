var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "department-base-info" },
    [
      _vm.hasCurrentCustomerAuditPermission
        ? _c(
            "div",
            { staticClass: "department-operate" },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { placement: "bottom" },
                  on: { command: _vm.handleIntegrate },
                },
                [
                  _c(
                    "span",
                    { staticClass: "text-operate-btn el-dropdown-link" },
                    [
                      _c("i", { staticClass: "el-icon-copy-document" }),
                      _vm._v("\n              整合部门\n          "),
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "integrate-department-dropdown",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    [
                      _c("el-dropdown-item", { attrs: { command: "merge" } }, [
                        _vm._v("合并到另一部门"),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: "sub" } }, [
                        _vm._v("成为另一部门子部门"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "text-operate-btn",
                  on: { click: _vm.handleEdit },
                },
                [
                  _c("i", { staticClass: "el-icon-edit-outline" }),
                  _vm._v("\n          编辑\n      "),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("p", { staticClass: "department-name" }, [
        _vm._v(_vm._s(_vm.department.name)),
      ]),
      _c(
        "p",
        {
          staticClass: "department-parent-name",
          attrs: { title: _vm.department.path },
        },
        [_vm._v("\n      " + _vm._s(_vm.department.path) + "\n  ")]
      ),
      _c("div", { staticClass: "department-member-count" }, [
        _c("i", { staticClass: "el-icon-user department-info-icon" }),
        _vm._v(
          "\n      " +
            _vm._s(_vm._f("memberCountFilter")(_vm.department.memberCount)) +
            "\n  "
        ),
      ]),
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.departmentBaseInfoTabs, function (tab, index) {
          return _c(
            "el-tab-pane",
            {
              key: "departmentBaseInfoTab_" + index,
              attrs: { name: tab.name },
            },
            [
              _c("template", { slot: "label" }, [
                _vm._v("\n              " + _vm._s(tab.label) + "\n          "),
              ]),
            ],
            2
          )
        }),
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName == "departmentIntroduction",
              expression: "activeName == 'departmentIntroduction'",
            },
          ],
          staticClass: "department-base-info-item",
        },
        [
          _c("p", { staticClass: "department-base-info-content" }, [
            _vm._v(_vm._s(_vm.department.description || "暂无部门简介")),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName == "businessIntroduction",
              expression: "activeName == 'businessIntroduction'",
            },
          ],
          staticClass: "department-base-info-item",
        },
        [
          _c("p", { staticClass: "department-base-info-content" }, [
            _vm._v(_vm._s(_vm.department.business || "暂无业务简介")),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName == "jobRequirements",
              expression: "activeName == 'jobRequirements'",
            },
          ],
          staticClass: "department-base-info-item",
        },
        [
          _c("p", { staticClass: "department-base-info-content" }, [
            _vm._v(_vm._s(_vm.department.requirement || "暂无岗位需求")),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName == "interviewProcess",
              expression: "activeName == 'interviewProcess'",
            },
          ],
          staticClass: "department-base-info-item",
        },
        [
          _c("p", { staticClass: "department-base-info-content" }, [
            _vm._v(_vm._s(_vm.department.interviewProcess || "暂无面试流程")),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName == "salaryStructure",
              expression: "activeName == 'salaryStructure'",
            },
          ],
          staticClass: "department-base-info-item",
        },
        [
          _c("p", { staticClass: "department-base-info-content" }, [
            _vm._v(_vm._s(_vm.department.salaryStructure || "暂无薪资构成")),
          ]),
        ]
      ),
      _c("department-merge-dialog", {
        ref: "depMergeDialog",
        attrs: {
          customerId: _vm.department.customerId,
          departmentId: _vm.department.id,
          departmentName: _vm.department.name,
          groupVersion: _vm.department.groupVersion,
        },
        on: {
          "transfer-done": function ($event) {
            return _vm.$emit("refresh-base-info")
          },
        },
      }),
      _c("edit-department-dialog", {
        ref: "departmentDialog",
        on: {
          refresh: function ($event) {
            return _vm.$emit("refresh-base-info")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }