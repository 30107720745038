<template>
    <div class="comment-list-wrapper">
        <h3 class="comment-list-title">
            <span class="title-active">评论与描述</span>
        </h3>

        <div class="comment-content">
            <div class="comment-list" v-loading="loading">
                <department-comment-item
                    v-for="(comment, index) in commentList"
                    :key="index"
                    :comment="comment"
                    @appraise-comment="appraiseComment"
                ></department-comment-item>
                <el-pagination
                    class="pagination-common-justify"
                    :current-page="pager.current"
                    layout="prev, pager, next, slot, total, sizes"
                    :total="pager.total"
                    v-if="commentList.length"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
                    <span class="pagination-text">
                        <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                        <span @click="handlePagerJump">跳转</span>
                    </span>
                </el-pagination>
                <div
                    class="empty-data"
                    v-if="commentList.length === 0">
                    <span class="empty-img"></span>
                    <p class="empty-text">暂无数据</p>
                </div>
            </div>
            <div
                class="comment-send-box"
                v-loading="sendLoading">
                <el-input
                    v-model="commentText"
                    type="textarea"
                    placeholder="填写新的评论"
                    :rows="3"
                    maxlength="200"
                    :show-word-limit="true"
                ></el-input>
                <div class="comment-send-btn">
                    <el-button
                        type="primary"
                        @click="sendComment">
                        提交
                    </el-button>
                    <el-button @click="commentText = ''">
                        取消
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DepartmentCommentItem from '../component/department-comment-item.vue';
import DepartmentService from '#/js/service/departmentService.js';
import moment from 'moment';
export default {
    props: {
        departmentId: String
    },
    components: {
        DepartmentCommentItem
    },
    data() {
        return {
            loading: false,
            commentList: [],
            pagerJump: 0,
            pager: {
                current: 1,
                size: 10,
                total: 1
            },
            commentText: "",
            sendLoading: false
        }
    },
    methods: {
        getDepartmentComments(isSlient) {
            this.loading = true;
            let params = {
                departmentId: this.departmentId
            };
            if(!isSlient) {
                this.pager.current = 1;
            }
            params.take = this.pager.size;
            params.start = (this.pager.current - 1) * params.take;
            DepartmentService.getDepartmentComments(params)
            .then(res => {
                this.loading = false;
                this.commentList = res.list;
                this.pager.total = res.total;
                if(isSlient) {
                    this.$el.querySelector(".comment-list-title").scrollIntoView();
                }
            }).catch(err => {
                this.loading = false;
                console.log(err);
            })
        },
        appraiseComment(comment) {
            if(comment.isLike) {
                shortTips("请勿重复点赞");
                return;
            } else {
                DepartmentService.departmentCommentLike({
                    commentId: comment.id
                }).then(res => {
                    this.$set(comment, 'isLike', true);
                    this.$set(comment, 'likeCount', comment.likeCount + 1);
                }).catch(err => {
                    console.log(err);
                })
            }
        },
        handleCurrentChange(val) {
            this.pager.current = val;
            this.getDepartmentComments(true);
        },
        handleSizeChange(val){
            this.pager.size = val;
            this.pager.current = 1;
            this.getDepartmentComments(true);
        },
        handlePagerJump(){
            let currentPager = Number(this.pagerJump),
            currentPageTotal = Math.ceil(this.pager.total / this.pager.size);
            if(currentPager > 0 && currentPager <= currentPageTotal){
                this.handleCurrentChange(currentPager)
            }
        },
        sendComment() {
            if(!this.commentText) {
                shortTips("评论不能为空!");
            } else {
                this.sendLoading = true;
                DepartmentService.addDepartmentComment({
                    departmentId: this.departmentId,
                    content: this.commentText,
                    today: moment().format('YYYY-MM-DD')
                }).then(res => {
                    this.sendLoading = false;
                    shortTips("评论成功!");
                    this.commentText = "";
                    this.pager.current = 1;
                    this.getDepartmentComments(true);
                }).catch(err => {
                    this.sendLoading = false;
                    console.log(err);
                });
            }
        }
    }
}
</script>

<style lang="scss" scope>
.comment-list-wrapper {
    background-color: #ffffff;
    border-radius: 8px;
    .comment-list-title {
        padding: 0 40px;
        margin: 0;
        height: 70px;
        line-height: 70px;
        font-size: 16px;
        border-bottom: 1px solid #eee;
        position: relative;
        .title-active {
            display: inline-block;
            height: 70px;
            color: #38BC9D;
            border-bottom: 4px solid #38BC9D;
        }
    }
    .comment-content {
        width: 1200px;
        padding: 40px 30px;
        .comment-list {
            border-bottom: 1px solid #ddd;
        }
        .department-comment-item + .department-comment-item {
            margin-top: 10px;
        }
        .empty-data {
            margin: 10px 0 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .empty-img {
                display: inline-block;
                background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                background-size: contain;
                width: 150px;
                height: 150px;
            }
            .empty-text {
                line-height: 20px;
                margin-bottom: 0px;
            }
        }
        .comment-send-box {
            margin-top: 40px;
            .el-textarea__inner {
                resize: none;
            }
            .comment-send-btn {
                margin-top: 20px;
                text-align: right;
            }
        }
    }
}
</style>