<template>
    <el-dialog
        custom-class="department-merge-dialog"
        :title="dialogTitle"
        :show-close="false"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        v-loading="mergeLoading">
        <div class="department-merge-tip">
            <template v-if="isMerge">
                <p>合并后，该部门所有信息将合并为你选中的部门信息。且所属的所有职位将归属于被选中的企业。</p>
            </template>
            <template v-else>
                <p>该部门所有信息将成为你选中部门的子部门。</p>
            </template>
        </div>
        <customer-department-select
            ref="departmentSelect"
            :customerId="customerId"
            :isSearchOnlyLeaf="isMerge"
            :isOnlyLeaf="isMerge"
            placeholder="搜索部门简称/全称"
            @update-department="updateDepartment"
        ></customer-department-select>
        <div class="dialog-footer" slot="footer">
            <el-button type="primary" @click="handleMerge">确定</el-button>
            <el-button @click="handleCloseDialog">取消</el-button>
    </div>
    </el-dialog>
</template>

<script>
import CustomerDepartmentSelect from '#/component/common/customer-department-select.vue'
import { department as departmentUrl } from '#/js/config/api.json';
export default {
    components: {
        CustomerDepartmentSelect
    },
    props: {
        customerId: {
            type: String,
            default: ""
        }, 
        departmentId: {
            type: [String, Number]
        },
        departmentName: {
            type: String,
            default: ""
        },
        groupVersion: {
            type: String,
            default: ""
        }
    },
    computed: {
        dialogTitle() {
            return this.isMerge ? '合并到另一部门' : '成为另一部门的子部门'
        }
    },
    data() {
        return {
            dialogVisible: false,
            mergeLoading: false,
            isMerge: true,
            mergeDepartmentId: "",
            mergeDepartmentName: "",
        }
    },
    mounted() {},
    methods: {
        show(isMerge) {
            this.isMerge = isMerge;
            this.dialogVisible = true;
        },
        updateDepartment(department) {
            this.mergeDepartmentId = department.id;
            this.mergeDepartmentName = department.name;
        },
        handleMerge() {
            if(!this.mergeDepartmentId) {
                shortTips('请选择存在的部门');
                return false;
            } else if(this.departmentId === this.mergeDepartmentId) {
                shortTips('不能选择当前部门!');
                return false;
            } else {
                let tip = "",
                    url = "",
                    shortTipsText = "",
                    json = {
                        from: this.departmentId,
                        to: this.mergeDepartmentId,
                        groupVersion: this.groupVersion
                    }
                if(this.isMerge) {
                    tip = `确定将现有部门"${this.departmentName}"合并到"${this.mergeDepartmentName}"，该操作不可恢复！`;
                    url = departmentUrl.merge_department;
                    shortTipsText = "合并成功";
                } else {
                    tip = `确定将现有部门"${this.departmentName}"整合成为"${this.mergeDepartmentName}"的子部门，该操作不可恢复`;
                    url = departmentUrl.transfer_department;
                    shortTipsText = "成功添加子部门";
                }
                this.$confirm(tip, '提示', {
                    type: 'warning',
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false
                }).then(res => {
                    this.mergeLoading = true;
                    _request({
                        url: url,
                        baseURL: "LbdOpenApi",
                        method: "POST",
                        // headers: {
                        //     'Content-Type': 'application/x-www-form-urlencoded'
                        // },
                        data: json
                    }).then(res => {
                        this.mergeLoading = false;
                        shortTips(shortTipsText);
                        this.handleCloseDialog();
                        if(this.isMerge) {
                            // location.href=`/Headhunting/MyCompany.html#/Customer/${this.customerId}/department`;
                            location.href=`/#/Customer/${this.customerId}/department`;
                        } else {
                            this.$emit('transfer-done');
                        }
                    }).catch(err => {
                        this.mergeLoading = false;
                    })
                }).catch(err => {})
            }
        },
        handleCloseDialog() {
            this.dialogVisible = false;
            this.mergeDepartmentId = "";
            this.$refs.departmentSelect.reset();
        }
    }
}
</script>

<style lang="scss" scope>
    .department-merge-dialog.el-dialog {
        width: 600px;
        .department-merge-tip {
            line-height: 20px;
            color: #666;
            font-size: 14px;
            p {
                margin-bottom: 10px;
            }
        }
    }
</style>
