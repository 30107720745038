<template>
  <div class="department-base-info">
    <div class="department-operate" v-if="hasCurrentCustomerAuditPermission">
        <el-dropdown @command="handleIntegrate" placement="bottom">
            <span class="text-operate-btn el-dropdown-link">
                <i class="el-icon-copy-document"></i>
                整合部门
            </span>
            <el-dropdown-menu class="integrate-department-dropdown" slot="dropdown">
                <el-dropdown-item command="merge">合并到另一部门</el-dropdown-item>
                <el-dropdown-item command="sub">成为另一部门子部门</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <span class="text-operate-btn" @click="handleEdit">
            <i class="el-icon-edit-outline"></i>
            编辑
        </span>
    </div>
    <p class="department-name">{{department.name}}</p>
    <p
        class="department-parent-name"
        :title="department.path">
        {{department.path}}
    </p>
    <div class="department-member-count">
        <i class="el-icon-user department-info-icon"></i>
        {{department.memberCount | memberCountFilter}}
    </div>

    <!-- tab -->
    <el-tabs v-model="activeName">
        <!-- :label="tab.label" -->
        <el-tab-pane
            v-for="(tab, index) in departmentBaseInfoTabs"
            :key="'departmentBaseInfoTab_' + index"
            :name="tab.name"
        >
            <template slot="label">
                {{ tab.label }}
            </template>
        </el-tab-pane>
    </el-tabs>
    <!-- 部门简介 -->
    <div class="department-base-info-item" v-show="activeName == 'departmentIntroduction'">
        <p class="department-base-info-content">{{department.description || "暂无部门简介"}}</p>
    </div>
    <!-- 业务简介 -->
    <div class="department-base-info-item" v-show="activeName == 'businessIntroduction'">
        <p class="department-base-info-content">{{department.business || "暂无业务简介"}}</p>
    </div>
    <!-- 岗位需求 -->
    <div class="department-base-info-item" v-show="activeName == 'jobRequirements'">
        <p class="department-base-info-content">{{department.requirement || "暂无岗位需求"}}</p>
    </div>
    <!-- 面试流程 -->
    <div class="department-base-info-item" v-show="activeName == 'interviewProcess'">
        <p class="department-base-info-content">{{department.interviewProcess || "暂无面试流程"}}</p>
    </div>
    <!-- 薪资构成 -->
    <div class="department-base-info-item" v-show="activeName == 'salaryStructure'">
        <p class="department-base-info-content">{{department.salaryStructure || "暂无薪资构成"}}</p>
    </div>
    
    <department-merge-dialog
        ref="depMergeDialog"
        :customerId="department.customerId"
        :departmentId="department.id"
        :departmentName="department.name"
        :groupVersion="department.groupVersion"
        @transfer-done="$emit('refresh-base-info')"
    ></department-merge-dialog>
    <edit-department-dialog
        ref="departmentDialog"
        @refresh="$emit('refresh-base-info')"
    ></edit-department-dialog>
  </div>
</template>

<script>
import DepartmentMergeDialog from '../component/department-merge-dialog.vue';
import EditDepartmentDialog from '#/views/customers/component/edit-department-dialog.vue';
import emitter from '#/js/mixins/emitter.js';

const MemberCountMap = {
    1: "少于50人",
    2: "50-150人",
    3: "150-500人",
    4: "500-1000人",
    5: "1000人以上"
}
export default {
    components: {
        DepartmentMergeDialog,
        EditDepartmentDialog
    },
    mixins: [emitter],
    props: {
        department: {
            type: Object
        }
    },
    data() {
        return {
            activeName: "departmentIntroduction",
            departmentBaseInfoTabs: [
                { label: "部门简介", name: "departmentIntroduction" },
                { label: "业务简介", name: "businessIntroduction" },
                { label: "岗位需求", name: "jobRequirements" },
                { label: "面试流程", name: "interviewProcess" },
                { label: "薪资构成", name: "salaryStructure" },
            ],
        }
    },
    computed: {
        hasCurrentCustomerAuditPermission() {
            let result = false;
            let auditCustomerList = this.$store.state.departmentAudit.customerList || [];
            if(auditCustomerList.length > 0) {
                auditCustomerList.some(item => {
                    if(item.id == this.department.customerId) {
                        result = true;
                        return true;
                    }
                })
            }
            return result;
        }
    },
    filters: {
        memberCountFilter(val) {
            return MemberCountMap[val];
        }
    },
    methods: {
        handleIntegrate(command) {
            this.$refs.depMergeDialog.show(command === 'merge');
        },
        handleEdit() {
            this.$refs.departmentDialog.show(true, {
                customerId: this.department.customerId,
                id: this.department.id,
                groupVersion: this.department.groupVersion
            });
        }
    }
}
</script>

<style lang="scss" scope>
    .department-base-info {
        // border: 1px solid #d3d3d3;
        // padding: 30px 20px;
        // border-top: 4px solid $primary;
        // margin-bottom: 36px;
        position: relative;
        background-color: #ffffff;
        padding: 40px 20px 30px 30px;
        margin-bottom: 20px;
        border-radius: 8px;
        .department-operate {
            position: absolute;
            top: 35px;
            right: 20px;
            line-height: 22px;
            .el-dropdown {
                margin-right: 30px;
            }
        }
        p {
            margin-bottom: 0;
        }
        .department-name {
            font-size: 24px;
            color: #333;
            line-height: 32px;
            margin-bottom: 14px;
        }
        .department-parent-name {
            color: #666;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 18px;
            word-break: break-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }
        .department-member-count {
            color: #666;
            line-height: 20px;
            margin-bottom: 32px;
            .department-info-icon {
                margin-right: 5px;
            }
        }
        &-item {
            margin-top: 15px;
            // & + & {
            //     margin-top: 32px;
            // }
        }
        &-title {
            padding: 0;
            margin: 10px 0;
            font-size: 18px;
            color: #333;
            height: 20px;
            line-height: 20px;
            position: relative;
            &::before {
                content: "";
                display: inline-block;
                width: 8px;
                height: 20px;
                margin-right: 9px;
                background-color: $primary;
                position: relative;
                top: -2px;
                vertical-align: middle;
            }
        }
        &-content {
            color: #666;
            font-size: 14px;
            line-height: 20px;
            white-space: pre-wrap;
            word-break: break-all;
        }
    }
</style>