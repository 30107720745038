var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "department-comment-item" }, [
    _c("div", { staticClass: "comment-item-left" }, [
      _c("p", { staticClass: "comment-text" }, [
        _vm._v(_vm._s(_vm.comment.content)),
      ]),
      _c("div", { staticClass: "comment-appraise" }, [
        _c(
          "span",
          {
            staticClass: "appraise-btn text-operate-btn grey",
            class: { "is-like": _vm.comment.isLike },
            on: {
              click: function ($event) {
                return _vm.$emit("appraise-comment", _vm.comment)
              },
            },
          },
          [
            !_vm.comment.isLike
              ? _c("i", { staticClass: "el-icon-star-off appraise-icon" })
              : _vm._e(),
            _vm.comment.isLike
              ? _c("i", { staticClass: "el-icon-star-on appraise-icon" })
              : _vm._e(),
            _vm._v("有用\n            "),
          ]
        ),
        _c("span", { staticClass: "appraise-tip" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.comment.likeCount) +
              " 位用户觉得该评论有用\n            "
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "comment-create" },
      [
        _c("avatar", {
          staticClass: "comment-create-creator",
          attrs: {
            size: "md",
            placement: "top",
            src: _vm.comment.avatar,
            alt: _vm.comment.creatorName,
            userId: _vm.comment.creatorId,
          },
        }),
        _c("span", { staticClass: "comment-create-time" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("timeFilter")(_vm.comment.created)) +
              " 发布\n        "
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }